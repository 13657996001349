body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-abstract {
  background-image: url('../public/images/abstract-shape.svg');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* TailwindCSS utility classes for the services page layout */

/* Base styles for the page */
.services-page {
  padding: 20px;
}

h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 10px;
}

p {
  text-align: center;
  margin-bottom: 30px;
}

/* Category Section */
.category-section {
  margin-top: 30px;
}

.category-section h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Service List */
.service-list {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.service-card {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-card h3 {
  font-size: 18px;
  font-weight: bold;
}

.service-card p {
  font-size: 14px;
  margin-bottom: 10px;
}

.service-card .btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.service-card .btn:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-card {
    width: 48%;
  }
}

@media (max-width: 480px) {
  .service-card {
    width: 100%;
  }
}
